<template>
  <v-dialog v-model="dialog" width="650">
    <template v-slot:activator="{ on }">
      <v-btn block elevation="0" v-on="on" style="height: 30px" class="mt-1">{{
        $t("configuration")
      }}</v-btn>
    </template>
    <v-card>
      <v-card-title>Estados</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row justify="center" class="py-1">
          <v-col align="center" cols="10" md="2" class="py-0 primary--text"
            >Solicitud</v-col
          >
          <v-col
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
            cols="10"
            class="py-0 white--text"
          >
            Citas nuevas que han entrado y aún no se han abierto
          </v-col>
          <v-col cols="12" class="py-1">
            <v-divider />
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="py-1">
          <v-col align="center" cols="10" md="2" class="py-0 orange--text"
            >Pendiente</v-col
          >
          <v-col
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
            cols="10"
            class="py-0 white--text"
          >
            Falta por rellenar alguno de los campos obligatorios.
          </v-col>
          <v-col cols="12" class="py-1"><v-divider /></v-col>
        </v-row>
        <v-row class="py-1" align="center" justify="center">
          <v-col align="center" cols="10" md="2" class="py-0 secondary--text"
            >Asignada</v-col
          >
          <v-col
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
            cols="7"
            class="py-0 white--text "
          >
            Todos los campos obligatorios rellenados
          </v-col>
          <v-col
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
            cols="10"
            md="3"
          >
            <v-dialog width="360" v-model="inner_dialog">
              <template v-slot:activator="{}">
                <v-btn height="30px" outlined @click="open"> Configurar </v-btn>
              </template>
              <v-card>
                <v-card-title>Campos obligatorios </v-card-title>
                <div class="close">
                  <v-btn icon @click="inner_dialog = false" small>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="12"
                      >Selecciona los campos obligatorios para que una cita esté
                      con el estado "ASIGNADA".
                    </v-col>
                    <v-col cols="12" md="8" class="py-0">Fecha</v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-select
                        outlined
                        dense
                        hide-details
                        :items="yesNo"
                        v-model="form.date"
                        disabled
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="8" class="py-0">Precio</v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-select
                        outlined
                        dense
                        hide-details
                        :items="yesNo"
                        v-model="form.date"
                        disabled
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="8" class="py-0">Artista</v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-select
                        outlined
                        dense
                        hide-details
                        :items="yesNo"
                        v-model="form.tattooer"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="8" class="py-0">Box</v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-select
                        outlined
                        dense
                        hide-details
                        :items="yesNo"
                        v-model="form.box"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="8" class="py-0">Presupuesto</v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-select
                        outlined
                        dense
                        hide-details
                        :items="yesNo"
                        v-model="form.presupuesto"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="8" class="py-0">Paga y señal</v-col>
                    <v-col cols="12" md="4" class="py-0">
                      <v-select
                        outlined
                        dense
                        hide-details
                        :items="yesNo"
                        v-model="form.token_payment"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="8" class="py-0"
                      >Hoja de consentimiento</v-col
                    >
                    <v-col cols="12" md="4" class="py-0">
                      <v-select
                        outlined
                        dense
                        hide-details
                        :items="yesNo"
                        v-model="form.consent"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    outlined
                    @click="inner_dialog = false"
                    style="height: 25px; width: 100px"
                    elevation="0"
                    >{{ $t("cancel") }}</v-btn
                  >
                  <v-btn
                    @click="save"
                    style="
                    width: 100px !important;
                    color: #363533;
                    height: 25px;
                    padding-top: 10px !important;
                  "
                    elevation="0"
                  >
                    <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                    {{ $t("save", { name: $t("") }) }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" class="py-1"><v-divider /></v-col>
        </v-row>
        <v-row align="center" justify="center" class="py-1">
          <v-col align="center" cols="10" md="2" class="py-0 red--text"
            >Check out</v-col
          >
          <v-col
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
            cols="10"
            class="py-0 white--text"
            >Citas pendientes con una sesión pasada</v-col
          >
          <v-col cols="12" class="py-1"><v-divider /></v-col>
        </v-row>
        <v-row align="center" justify="center" class="py-1">
          <v-col align="center" cols="10" md="2" class="py-0 gris1--text"
            >Cancelada</v-col
          >
          <v-col
            :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
            cols="10"
            class="py-0 white--text"
            >Citas anuladas</v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
    <!--
    <v-card v-else>
      <v-card-title>Estados</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row justify="center">
          
          <v-col cols="10" sm="7">
            <base-border-card
              class="mb-4 text-center"
              id="solicitud"
              :title="$t('solicitud')"
              :text="$t('Citas nuevas que han entrado y aún no se han abierto')"
            >
            </base-border-card>
            <base-border-card
              id="pending"
              class="mb-4 text-center"
              :title="$t('pending')"
              :text="$t('Falta por rellenar alguno de los campos obligatorios')"
            >
            </base-border-card>
            <base-border-card
              id="asigned"
              class="mb-4 text-center"
              :title="$t('asigned')"
              :text="$t('Todos los campos obligatorios rellenados')"
            >
            </base-border-card>
            <base-border-card
              class="mb-4 text-center"
              id="checkout"
              :title="$t('appointments.states.check_out')"
              :text="$t('Citas pendientes con una sesión pasada')"
            >
            </base-border-card>
            <base-border-card
              class="mb-4 text-center"
              id="canceled"
              :title="$t('canceled')"
              :text="$t('Citas anuladas')"
            >
            </base-border-card>
          </v-col>
          
        </v-row>
      </v-card-text>
    </v-card>
    -->
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "",
  data() {
    return {
      dialog: false,
      yesNo: [
        { text: this.$t("yes"), value: true },
        { text: this.$t("no"), value: false },
      ],
      inner_dialog: false,
      form: {
        tattooer: false,
        date: false,
        box: false,
        presupuesto: false,
        token_payment: false,
        consent: false,
      },
    };
  },
  methods: {
    ...mapActions("appointments", [
      "getPendingConfiguration",
      "updatePendingConfiguration",
    ]),
    save() {
      this.updatePendingConfiguration({
        value: this.form,
        json: true,
        key: "pending_setting",
      }).then(() => {
        this.inner_dialog = false;
      });
    },
    open() {
      this.getPendingConfiguration().then((response) => {
        this.inner_dialog = true;
        this.form = response;
      });
    },
  },
};
</script>
<style lang="sass">
#solicitud

  border: 1px solid var(--v-primary-base)
#checkout
  border: 1px solid red
#asigned
  border: 1px solid var(--v-secondary-base)
#pending
  border: 1px solid orange
#canceled
  border: 1px solid grey
</style>
